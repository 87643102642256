var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"mx-2"},[_c('h3',{staticClass:"mt-4"},[_vm._v(" "+_vm._s(_vm.$getVisibleNames("mesh.genericcareer", false, "Carrera Genérica"))+" "),_c('AlternativeNameButton',{attrs:{"table_name":'mesh.genericcareer',"allows_crud":_vm.allows_crud}})],1),_c('div',{staticStyle:{"display":"flex"}},[_c('div',{staticClass:"col",staticStyle:{"text-align":"left"}},[(_vm.allows_crud)?_c('button',{directives:[{name:"can",rawName:"v-can",value:('mesh.add_genericcareer'),expression:"'mesh.add_genericcareer'"}],staticClass:"btn btn-secondary btn-sm",on:{"click":function($event){return _vm.$bvModal.show(`new-generic_career-modal`)}}},[_vm._v(" Agregar "+_vm._s(_vm.$getVisibleNames("mesh.genericcareer", false, "Carrera Genérica"))+" ")]):_vm._e()])]),_c('GenericBTable',{attrs:{"items":_vm.genericCareers,"pagination":_vm.genericCareers.length,"fields":_vm.genericCareersFields,"filter":_vm.input_search,"show_pagination":true,"search_filter":true,"columns_display":true,"allows_crud":_vm.allows_crud},scopedSlots:_vm._u([{key:"cell(description)",fn:function(row){return [_c('div',{staticClass:"rich-text-content",domProps:{"innerHTML":_vm._s(row.item.description)}})]}},(_vm.allows_crud)?{key:"cell(actions)",fn:function(row){return [_c('button-edit',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive",value:(
            `Editar ${_vm.$getVisibleNames(
              'mesh.genericcareer',
              false,
              'Carrera Genérica'
            )}`
          ),expression:"\n            `Editar ${$getVisibleNames(\n              'mesh.genericcareer',\n              false,\n              'Carrera Genérica'\n            )}`\n          ",modifiers:{"v-secondary":true,"noninteractive":true}}],on:{"click":function($event){return _vm.$bvModal.show(`edit-generic_career-modal-${row.item.id}`)}}}),_c('button-delete',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive",value:(
            `Eliminar ${_vm.$getVisibleNames(
              'mesh.genericcareer',
              false,
              'Carrera Genérica'
            )}`
          ),expression:"\n            `Eliminar ${$getVisibleNames(\n              'mesh.genericcareer',\n              false,\n              'Carrera Genérica'\n            )}`\n          ",modifiers:{"v-secondary":true,"noninteractive":true}}],on:{"click":function($event){return _vm.askForDeleteGenericCareer(row.item.id)}}}),_c('b-modal',{attrs:{"id":`edit-generic_career-modal-${row.item.id}`,"title":`Editar ${_vm.$getVisibleNames(
            'mesh.genericcareer',
            false,
            'Carrera Genérica'
          )}`,"size":"md","hide-footer":""}},[_c('GenericCareerForm',{attrs:{"GenericCareer":row.item,"show_title":false},on:{"updated":_vm.slotUpdatedGenericCareer}})],1)]}}:null],null,true)})],1),_c('b-modal',{attrs:{"id":`new-generic_career-modal`,"hide-footer":true,"title":`Crear ${_vm.$getVisibleNames(
      'mesh.genericcareer',
      false,
      'Carrera Genérica'
    )}`,"size":"md"}},[_c('GenericCareerForm',{on:{"created":_vm.slotCreatedGenericCareer}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }